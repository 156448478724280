import React, { useState, useCallback } from "react";
import {
  Box,
  VStack,
  HStack,
  Button,
  Text,
  Flex,
  useColorModeValue,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Icon,
  IconButton,
  Card,
  CardHeader,
  CardBody,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  Badge,
  Tooltip,
  Divider,
} from "@chakra-ui/react";
import {
  FaGripVertical,
  FaDownload,
  FaBars,
  FaFilter,
  FaSort,
  FaSearchPlus,
  FaSearchMinus,
  FaTrash,
  FaSortAmountDown,
  FaSortAmountUp,
} from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { Layout } from "../components/Layout";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

const AnalyticsBuilder = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Color mode values
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const dropZoneBg = useColorModeValue("gray.50", "gray.700");
  const dimensionBg = useColorModeValue("blue.50", "blue.900");
  const measureBg = useColorModeValue("green.50", "green.900");
  const itemBg = useColorModeValue("white", "gray.700");
  const navigate = useNavigate(); // Initialize useNavigate hook

  // Sample dimensions and measures
  const dimensions = [
    {
      id: "date",
      name: "Date",
      type: "dimension",
      drillDownTo: ["month", "day"],
    },
    {
      id: "region",
      name: "Region",
      type: "dimension",
      drillDownTo: ["country", "city"],
    },
    {
      id: "product",
      name: "Product",
      type: "dimension",
      drillDownTo: ["category", "subcategory"],
    },
    {
      id: "customer",
      name: "Customer",
      type: "dimension",
      drillDownTo: ["segment", "name"],
    },
  ];

  const measures = [
    { id: "sales", name: "Sales", type: "measure" },
    { id: "profit", name: "Profit", type: "measure" },
    { id: "quantity", name: "Quantity", type: "measure" },
    { id: "discount", name: "Discount", type: "measure" },
  ];

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filters, setFilters] = useState({});
  const [sortConfigs, setSortConfigs] = useState({});
  const [drillLevels, setDrillLevels] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  // Drag and drop handlers
  const onDragStart = (e, item) => {
    e.dataTransfer.setData("text", JSON.stringify(item));
  };

  const onDrop = (e, target) => {
    e.preventDefault();
    const item = JSON.parse(e.dataTransfer.getData("text"));

    if (target === "rows") {
      if (!rows.find((r) => r.id === item.id)) {
        setRows([...rows, item]);
      }
    } else if (target === "columns") {
      if (!columns.find((c) => c.id === item.id)) {
        setColumns([...columns, item]);
      }
    }
    onClose();
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const removeField = (id, target) => {
    if (target === "rows") {
      setRows(rows.filter((r) => r.id !== id));
    } else {
      setColumns(columns.filter((c) => c.id !== id));
    }
    // Clear associated filters and sorts
    const newFilters = { ...filters };
    delete newFilters[id];
    setFilters(newFilters);

    const newSortConfigs = { ...sortConfigs };
    delete newSortConfigs[id];
    setSortConfigs(newSortConfigs);
  };

  const clearAll = (target) => {
    if (target === "rows") {
      setRows([]);
    } else if (target === "columns") {
      setColumns([]);
    } else if (target === "all") {
      setRows([]);
      setColumns([]);
      setFilters({});
      setSortConfigs({});
      setDrillLevels({});
    }
  };

  const addFilter = (fieldId) => {
    setFilters({
      ...filters,
      [fieldId]: { enabled: true, value: "" },
    });
  };

  const toggleSort = (fieldId) => {
    setSortConfigs({
      ...sortConfigs,
      [fieldId]: sortConfigs[fieldId] === "asc" ? "desc" : "asc",
    });
  };

  const drillDown = (fieldId) => {
    const field = [...dimensions, ...measures].find((f) => f.id === fieldId);
    if (field?.drillDownTo?.length > 0) {
      const currentLevel = drillLevels[fieldId] || 0;
      if (currentLevel < field.drillDownTo.length) {
        setDrillLevels({
          ...drillLevels,
          [fieldId]: currentLevel + 1,
        });
      }
    }
  };

  const drillUp = (fieldId) => {
    if (drillLevels[fieldId] > 0) {
      setDrillLevels({
        ...drillLevels,
        [fieldId]: drillLevels[fieldId] - 1,
      });
    }
  };

  const handleSearchChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const downloadCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      rows.map((r) => r.name).join(",") +
      "," +
      columns.map((c) => c.name).join(",") +
      "\n";

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const FieldItem = ({ item, target }) => (
    <Flex
      align="center"
      px={3}
      py={1}
      bg={item.type === "dimension" ? dimensionBg : measureBg}
      rounded="md"
      shadow="sm"
    >
      <Text>{item.name}</Text>
      <HStack spacing={1} ml={2}>
        {filters[item.id] && (
          <Tooltip label="Filtered">
            <Icon as={FaFilter} color="blue.500" />
          </Tooltip>
        )}
        {sortConfigs[item.id] && (
          <Tooltip
            label={`Sorted ${
              sortConfigs[item.id] === "asc" ? "ascending" : "descending"
            }`}
          >
            <Icon
              as={
                sortConfigs[item.id] === "asc"
                  ? FaSortAmountUp
                  : FaSortAmountDown
              }
              color="purple.500"
            />
          </Tooltip>
        )}
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<FaSort />}
            variant="ghost"
            size="xs"
          />
          <MenuList>
            <MenuItem onClick={() => addFilter(item.id)}>
              <Icon as={FaFilter} mr={2} />
              Add Filter
            </MenuItem>
            <MenuItem onClick={() => toggleSort(item.id)}>
              <Icon as={FaSort} mr={2} />
              Toggle Sort
            </MenuItem>
            {item.type === "dimension" && item.drillDownTo?.length > 0 && (
              <>
                <MenuItem onClick={() => drillDown(item.id)}>
                  <Icon as={FaSearchPlus} mr={2} />
                  Drill Down
                </MenuItem>
                <MenuItem onClick={() => drillUp(item.id)}>
                  <Icon as={FaSearchMinus} mr={2} />
                  Drill Up
                </MenuItem>
              </>
            )}
            <MenuItem
              onClick={() => removeField(item.id, target)}
              color="red.500"
            >
              <Icon as={FaTrash} mr={2} />
              Remove
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Flex>
  );

  const Sidebar = () => (
    <VStack spacing={6} align="stretch" w="full">
      <HStack spacing={2} align="center">
        <IconButton
          icon={<FaArrowLeft />}
          variant="ghost"
          onClick={handleBackClick}
          aria-label="Go back"
          size="sm"
          className="back-button"
        />
        <Input
          placeholder="Search fields..."
          value={searchTerm}
          onChange={handleSearchChange}
          flex={1}
        />
      </HStack>
      <Box>
        <Text fontWeight="semibold" mb={2}>
          Dimensions
        </Text>
        <VStack spacing={2} align="stretch">
          {dimensions
            .filter((dim) =>
              dim.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((dim) => (
              <Flex
                key={dim.id}
                draggable
                onDragStart={(e) => onDragStart(e, dim)}
                align="center"
                p={2}
                bg={dimensionBg}
                rounded="md"
                cursor="move"
                _hover={{ opacity: 0.8 }}
              >
                <Icon as={FaGripVertical} mr={2} color="blue.500" />
                <Text>{dim.name}</Text>
                {dim.drillDownTo?.length > 0 && (
                  <Tooltip
                    label={`Can drill down to: ${dim.drillDownTo.join(", ")}`}
                  >
                    <Icon as={FaSearchPlus} ml={2} color="gray.500" />
                  </Tooltip>
                )}
              </Flex>
            ))}
        </VStack>
      </Box>

      <Box>
        <Text fontWeight="semibold" mb={2}>
          Measures
        </Text>
        <VStack spacing={2} align="stretch">
          {measures
            .filter((measure) =>
              measure.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((measure) => (
              <Flex
                key={measure.id}
                draggable
                onDragStart={(e) => onDragStart(e, measure)}
                align="center"
                p={2}
                bg={measureBg}
                rounded="md"
                cursor="move"
                _hover={{ opacity: 0.8 }}
              >
                <Icon as={FaGripVertical} mr={2} color="green.500" />
                <Text>{measure.name}</Text>
              </Flex>
            ))}
        </VStack>
      </Box>
    </VStack>
  );

  const showSidebarButton = useBreakpointValue({ base: true, md: false });

  const handleBackClick = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const mainIconLabel = searchParams.get("mainIcon");
    navigate(`/LandingPage/?mainIcon=${mainIconLabel}`);
  };

  return (
    <Layout>
      <Flex minH="100vh" bg={bgColor}>
        {/* Desktop Sidebar */}
        <Box
          display={{ base: "none", md: "block" }}
          w="64"
          p={4}
          borderRight="1px"
          borderColor={borderColor}
        >
          <Sidebar />
        </Box>

        {/* Mobile Sidebar Drawer */}
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Fields</DrawerHeader>
            <DrawerBody>
              <Sidebar />
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        {/* Main Content */}
        <Box flex={1} p={4}>
          {showSidebarButton && (
            <IconButton
              icon={<FaBars />}
              onClick={onOpen}
              mb={4}
              aria-label="Open sidebar"
            />
          )}

          <Card mb={6}>
            <CardHeader>
              <Flex justify="space-between" align="center">
                <Text fontSize="xl" fontWeight="bold">
                  Analytics Builder
                </Text>
                <Button
                  leftIcon={<FaTrash />}
                  colorScheme="red"
                  variant="ghost"
                  size="sm"
                  onClick={() => clearAll("all")}
                >
                  Clear All
                </Button>
              </Flex>
            </CardHeader>
            <CardBody>
              {/* Rows drop zone */}
              <Box mb={4}>
                <Flex justify="space-between" align="center" mb={2}>
                  <Text fontSize="sm" fontWeight="medium">
                    Rows
                  </Text>
                  <Button
                    size="xs"
                    variant="ghost"
                    colorScheme="red"
                    leftIcon={<FaTrash />}
                    onClick={() => clearAll("rows")}
                  >
                    Clear
                  </Button>
                </Flex>
                <Box
                  onDrop={(e) => onDrop(e, "rows")}
                  onDragOver={onDragOver}
                  minH="16"
                  p={4}
                  border="2px"
                  borderStyle="dashed"
                  borderColor={borderColor}
                  rounded="lg"
                  bg={dropZoneBg}
                >
                  <Flex wrap="wrap" gap={2}>
                    {rows.map((item) => (
                      <FieldItem key={item.id} item={item} target="rows" />
                    ))}
                  </Flex>
                </Box>
              </Box>

              {/* Columns drop zone */}
              <Box mb={4}>
                <Flex justify="space-between" align="center" mb={2}>
                  <Text fontSize="sm" fontWeight="medium">
                    Columns
                  </Text>
                  <Button
                    size="xs"
                    variant="ghost"
                    colorScheme="red"
                    leftIcon={<FaTrash />}
                    onClick={() => clearAll("columns")}
                  >
                    Clear
                  </Button>
                </Flex>
                <Box
                  onDrop={(e) => onDrop(e, "columns")}
                  onDragOver={onDragOver}
                  minH="16"
                  p={4}
                  border="2px"
                  borderStyle="dashed"
                  borderColor={borderColor}
                  rounded="lg"
                  bg={dropZoneBg}
                >
                  <Flex wrap="wrap" gap={2}>
                    {columns.map((item) => (
                      <FieldItem key={item.id} item={item} target="columns" />
                    ))}
                  </Flex>
                </Box>
              </Box>

              {/* Continuing from Active Filters Display */}
              {Object.keys(filters).length > 0 && (
                <Box mb={4}>
                  <Text fontSize="sm" fontWeight="medium" mb={2}>
                    Active Filters
                  </Text>
                  <Flex wrap="wrap" gap={2}>
                    {Object.entries(filters).map(([fieldId, filter]) => {
                      const field = [...dimensions, ...measures].find(
                        (f) => f.id === fieldId
                      );
                      return (
                        <Badge
                          key={fieldId}
                          colorScheme={
                            field?.type === "dimension" ? "blue" : "green"
                          }
                          p={2}
                          display="flex"
                          alignItems="center"
                        >
                          <Text>{field?.name} Filter</Text>
                          <IconButton
                            icon={<FaTrash />}
                            size="xs"
                            variant="ghost"
                            ml={2}
                            onClick={() => {
                              const newFilters = { ...filters };
                              delete newFilters[fieldId];
                              setFilters(newFilters);
                            }}
                            aria-label="Remove filter"
                          />
                        </Badge>
                      );
                    })}
                  </Flex>
                </Box>
              )}

              {/* Active Sorts Display */}
              {Object.keys(sortConfigs).length > 0 && (
                <Box mb={4}>
                  <Text fontSize="sm" fontWeight="medium" mb={2}>
                    Active Sorts
                  </Text>
                  <Flex wrap="wrap" gap={2}>
                    {Object.entries(sortConfigs).map(([fieldId, direction]) => {
                      const field = [...dimensions, ...measures].find(
                        (f) => f.id === fieldId
                      );
                      return (
                        <Badge
                          key={fieldId}
                          colorScheme="purple"
                          p={2}
                          display="flex"
                          alignItems="center"
                        >
                          <Icon
                            as={
                              direction === "asc"
                                ? FaSortAmountUp
                                : FaSortAmountDown
                            }
                            mr={2}
                          />
                          <Text>{field?.name}</Text>
                          <IconButton
                            icon={<FaTrash />}
                            size="xs"
                            variant="ghost"
                            ml={2}
                            onClick={() => {
                              const newSortConfigs = { ...sortConfigs };
                              delete newSortConfigs[fieldId];
                              setSortConfigs(newSortConfigs);
                            }}
                            aria-label="Remove sort"
                          />
                        </Badge>
                      );
                    })}
                  </Flex>
                </Box>
              )}

              {/* Drill Levels Display */}
              {Object.keys(drillLevels).length > 0 && (
                <Box mb={4}>
                  <Text fontSize="sm" fontWeight="medium" mb={2}>
                    Drill Levels
                  </Text>
                  <Flex wrap="wrap" gap={2}>
                    {Object.entries(drillLevels).map(([fieldId, level]) => {
                      const field = dimensions.find((f) => f.id === fieldId);
                      if (!field) return null;
                      return (
                        <Badge
                          key={fieldId}
                          colorScheme="orange"
                          p={2}
                          display="flex"
                          alignItems="center"
                        >
                          <Text>
                            {field.name} → {field.drillDownTo[level - 1]}
                          </Text>
                          <IconButton
                            icon={<FaTrash />}
                            size="xs"
                            variant="ghost"
                            ml={2}
                            onClick={() => {
                              const newDrillLevels = { ...drillLevels };
                              delete newDrillLevels[fieldId];
                              setDrillLevels(newDrillLevels);
                            }}
                            aria-label="Reset drill level"
                          />
                        </Badge>
                      );
                    })}
                  </Flex>
                </Box>
              )}
            </CardBody>
          </Card>

          {/* Results Preview Card */}
          <Card mb={6}>
            <CardHeader>
              <Text fontSize="lg" fontWeight="bold">
                Results Preview
              </Text>
            </CardHeader>
            <CardBody>
              <Text color="gray.500" textAlign="center">
                Data preview will appear here based on selected dimensions,
                measures, and applied operations
              </Text>
            </CardBody>
          </Card>

          {/* Action Buttons */}
          <HStack spacing={4} justify="flex-end">
            <Button
              leftIcon={<FaTrash />}
              variant="ghost"
              colorScheme="red"
              onClick={() => clearAll("all")}
            >
              Clear All
            </Button>
            <Button
              leftIcon={<FaDownload />}
              colorScheme="blue"
              isDisabled={rows.length === 0 && columns.length === 0}
              onClick={downloadCSV}
            >
              Download CSV
            </Button>
          </HStack>
        </Box>
      </Flex>
    </Layout>
  );
};

export default AnalyticsBuilder;
