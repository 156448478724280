import React, { useState } from "react";
import {
  ChakraProvider,
  Box,
  VStack,
  HStack,
  Button,
  Text,
  Stack,
  Icon,
  useColorModeValue,
  useBreakpointValue,
  Flex,
  Image,
} from "@chakra-ui/react";
import {
  FaChartBar,
  FaTable,
  FaChartPie,
  FaChartLine,
  FaArrowLeft,
} from "react-icons/fa";
import { Layout } from "../components/Layout";
import Report1 from "../Icons/PowerBI.png"; // Import Report1 icon
import { useNavigate } from "react-router-dom"; // Import useNavigate hook

const PowerBI = () => {
  const [currentIframe, setCurrentIframe] = useState(
    "https://app.powerbi.com/view?r=eyJrIjoiMjEwYjgzNWUtZGQ4Ni00ODMwLWI0NjgtNzk3NjkxODIwNDM4IiwidCI6IjFmZjk0MGQ4LWFkOGEtNDNkZi1iZjQxLWI2OThkMWJkODVmNiIsImMiOjh9"
  );

  //const iconColor = useColorModeValue("black", "white");
  const ribbonWidth = useBreakpointValue({ base: "100%", md: "10%" });
  const direction = useBreakpointValue({ base: "row", md: "column" });
  const navigate = useNavigate(); // Initialize useNavigate hook

  const iframeList = [
    {
      src: "https://app.powerbi.com/view?r=eyJrIjoiMjEwYjgzNWUtZGQ4Ni00ODMwLWI0NjgtNzk3NjkxODIwNDM4IiwidCI6IjFmZjk0MGQ4LWFkOGEtNDNkZi1iZjQxLWI2OThkMWJkODVmNiIsImMiOjh9",
      icon: FaChartBar,
    },
    {
      src: "https://app.powerbi.com/view?r=eyJrIjoiMzJjY2U4ZDgtODYyNC00NGQwLTkxNjMtZjY5N2Q4ZWJjZGExIiwidCI6ImI3M2IxZDZlLTIxZDUtNGUzOC1iMjM5LTgxMzRkOWQyYmY3OCIsImMiOjh9",
      icon: FaChartLine,
    },
    {
      src: "https://app.powerbi.com/view?r=eyJrIjoiNmY1Yzc0YWMtMmVlNS00NzhiLWExZTItYjIyNWUwNTVlYmZmIiwidCI6ImI3M2IxZDZlLTIxZDUtNGUzOC1iMjM5LTgxMzRkOWQyYmY3OCIsImMiOjh9",
      icon: FaTable,
    },
  ];

  const handleBackClick = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const mainIconLabel = searchParams.get("mainIcon");
    navigate(`/LandingPage/?mainIcon=${mainIconLabel}`);
  };

  return (
    <Layout>
      <ChakraProvider>
        <HStack
          height="100vh"
          spacing={0}
          flexDirection={{ base: "column", md: "row" }}
          alignItems="stretch"
        >
          <Flex
            direction={direction}
            spacing={4}
            align="stretch"
            bg="black"
            padding={4}
            width={ribbonWidth}
            height={{ base: "auto", md: "100vh" }}
            overflowY={{ base: "auto", md: "hidden" }}
            flexShrink={0}
          >
            <Button
              onClick={handleBackClick}
              color="white"
              justifyContent="center"
              variant="ghost"
              bg="transparent"
              _hover={{ bg: "gray.500" }}
              _active={{ bg: "gray.400" }}
              py={4}
              width={{ base: "auto", md: "100%" }}
              mx={{ base: 2, md: 0 }}
            >
              <Stack align="center" spacing={1}>
                <Icon as={FaArrowLeft} w={4} h={4} color="white" />
              </Stack>
            </Button>

            {/* Add the Report1 icon as the first item */}
            <Button
              color="white"
              justifyContent="center"
              variant="ghost"
              bg="transparent"
              _hover={{ bg: "gray.500" }}
              _active={{ bg: "gray.400" }}
              py={7}
              width={{ base: "auto", md: "100%" }}
              mx={{ base: 2, md: 0 }}
            >
              <Stack align="center" spacing={1}>
                <Image src={Report1} boxSize="36px" alt="Report Icon" />
              </Stack>
            </Button>

            {iframeList.map((iframe, index) => (
              <Button
                key={index}
                onClick={() => setCurrentIframe(iframe.src)}
                color="white"
                justifyContent="center"
                variant={currentIframe === iframe.src ? "solid" : "ghost"}
                bg={currentIframe === iframe.src ? "gray.600" : "transparent"}
                _hover={{ bg: "gray.500" }}
                _active={{ bg: "gray.400" }}
                py={7}
                width={{ base: "auto", md: "100%" }}
                mx={{ base: 2, md: 0 }}
              >
                <Stack align="center" spacing={1}>
                  <Icon as={iframe.icon} w={6} h={6} color="white" />
                </Stack>
              </Button>
            ))}
          </Flex>
          <Box
            flex="1"
            width={{ base: "100%", md: "90%" }}
            height={{ base: "calc(100vh - 150px)", md: "100vh" }}
            padding={5}
            overflow="hidden"
            bg="white"
            display="flex"
            flexDirection="column"
            alignItems="flex-start" // Left-align items
            justifyContent="flex-start"
            borderLeft={{ base: "none", md: "none" }}
          >
            {/* Gray medium-sized header */}
            <Text fontSize="xl" color="gray.500" mb={2}>
              Sporting
            </Text>
            {/* Black larger header */}
            <Text fontSize="2xl" color="black" fontWeight="bold" mb={4}>
              Power BI Example
            </Text>
            {currentIframe && (
              <Box
                width="100%"
                height="100%"
                border="0px solid gray"
                borderRadius="xl" // Rounded border
                padding="5" // Padding around the iframe
                flex="1"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <iframe
                  src={currentIframe}
                  title="Tableau Visualization"
                  width="100%"
                  height="100%"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "16px",
                  }} // Border radius for iframe
                />
              </Box>
            )}
          </Box>
        </HStack>
      </ChakraProvider>
    </Layout>
  );
};

export default PowerBI;
