import {
  Button,
  chakra,
  FormControl,
  Heading,
  HStack,
  Input,
  Stack,
  useToast,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { ViewIcon, ViewOffIcon, EmailIcon } from "@chakra-ui/icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Card } from "../components/Card";
import { Layout } from "../components/Layout";
import { useAuth } from "../contexts/AuthContext";
import useMounted from "../hooks/useMounted";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../utils/init-firebase";

export default function Loginpage() {
  const Navigate = useNavigate();
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const location = useLocation();
  const mounted = useMounted();
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const inputBg = useColorModeValue("gray.20", "rgba(255, 255, 255, 0.1)");
  const iconColor = useColorModeValue("black", "white");
  const textColor = useColorModeValue("black", "white");
  const placeholderColor = useColorModeValue("gray.600", "white");

  // Handle redirect based on user type
  const handlePostLoginRedirect = async (userId) => {
    try {
      const userProfileRef = doc(db, "userProfile", userId);
      const userProfileSnap = await getDoc(userProfileRef);

      if (userProfileSnap.exists()) {
        const userProfileData = userProfileSnap.data();

        // Check if the user is a UniversityUser
        if (userProfileData.role === "UniversityUser") {
          Navigate("/University"); // Redirect to /University if the user is a UniversityUser
        } else {
          // If not a UniversityUser, check the profile completion and redirect accordingly
          const { completionPercentage } = userProfileData;
          Navigate(completionPercentage === 100 ? "/LandingPage" : "/profile");
        }
      } else {
        Navigate("/profile");
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      Navigate("/profile");
    }
  };

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(70vh - 16px)",
        }}
      >
        <Card maxW="md" mx="auto" mt={4} bg="transparent" boxShadow="none">
          <Heading textAlign="center" my={8} color={textColor}>
            Log in to your account
          </Heading>
          <chakra.form
            onSubmit={async (e) => {
              e.preventDefault();
              if (!email || !password) {
                toast({
                  description: "Credentials not valid.",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
                return;
              }
              setIsSubmitting(true);
              login(email, password)
                .then((res) => handlePostLoginRedirect(res.user.uid))
                .catch((error) => {
                  toast({
                    description: error.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                })
                .finally(() => mounted.current && setIsSubmitting(false));
            }}
          >
            <Stack spacing="6">
              <FormControl id="email">
                <InputGroup size="md">
                  <InputLeftElement
                    pointerEvents="none"
                    color={iconColor}
                    fontSize="1.2em"
                    ml="2"
                    children={<EmailIcon style={{ verticalAlign: "middle" }} />}
                  />
                  <Input
                    pr="4.5rem"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    bg={inputBg}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    pl="3.5rem"
                    pt="5"
                    pb="5"
                    borderRadius="md"
                    textAlign="left"
                    style={{ lineHeight: "initial" }}
                    color={textColor}
                    placeholder="Email"
                    _placeholder={{ color: placeholderColor }}
                  />
                </InputGroup>
              </FormControl>
              <FormControl id="password">
                <InputGroup size="md">
                  <InputLeftElement
                    pointerEvents="none"
                    color={iconColor}
                    fontSize="1.2em"
                    ml="2"
                    children={<FaLock style={{ verticalAlign: "middle" }} />}
                  />
                  <Input
                    pr="4.5rem"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="password"
                    required
                    bg={inputBg}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    pl="3.5rem"
                    pt="5"
                    pb="5"
                    borderRadius="md"
                    textAlign="left"
                    style={{ lineHeight: "initial" }}
                    color={textColor}
                    placeholder="Password"
                    _placeholder={{ color: placeholderColor }}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      bg={useColorModeValue("blackAlpha.300", "whiteAlpha.300")}
                      size="sm"
                      color={textColor}
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? (
                        <ViewOffIcon style={{ verticalAlign: "middle" }} />
                      ) : (
                        <ViewIcon style={{ verticalAlign: "middle" }} />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <HStack justifyContent="space-between" my={4}>
                <Button variant="link">
                  <Link
                    to="/forgot-password"
                    color={useColorModeValue("black", "white")}
                    _hover={{
                      textDecoration: "underline",
                      opacity: 0.8,
                    }}
                  >
                    Forgot password?
                  </Link>
                </Button>
                <Button variant="link">
                  <Link
                    to="/demo"
                    color={useColorModeValue("black", "white")}
                    _hover={{
                      textDecoration: "underline",
                      opacity: 0.8,
                    }}
                  >
                    Contact Us
                  </Link>
                </Button>
              </HStack>
              <Button
                type="submit"
                bg={useColorModeValue("blackAlpha.300", "whiteAlpha.300")}
                color={useColorModeValue("black", "white")}
                _hover={{
                  bg: useColorModeValue("blackAlpha.400", "whiteAlpha.400"),
                }}
                fontSize="md"
                isLoading={isSubmitting}
                rounded="full"
              >
                Log in
              </Button>
            </Stack>
          </chakra.form>
        </Card>
      </div>
    </Layout>
  );
}
