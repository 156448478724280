import React, { useState } from "react";
import {
  ChakraProvider,
  Box,
  HStack,
  Button,
  Text,
  Stack,
  Icon,
  useBreakpointValue,
  Flex,
  Image,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import {
  FaChartBar,
  FaArrowLeft,
  FaSearchPlus,
  FaSearchMinus,
  FaUndo,
} from "react-icons/fa";
import { Layout } from "../components/Layout";
import Report1 from "../Icons/powerautomate.png";
import { useNavigate } from "react-router-dom";
import PowerAutomateImage from "../Icons/Process.png";

const PowerAutomate = () => {
  const [currentIframe, setCurrentIframe] = useState(PowerAutomateImage);
  const [scale, setScale] = useState(1);
  const ribbonWidth = useBreakpointValue({ base: "100%", md: "10%" });
  const direction = useBreakpointValue({ base: "row", md: "column" });
  const navigate = useNavigate();

  const iframeList = [
    {
      src: PowerAutomateImage,
      title: "Power Automate",
      icon: FaChartBar,
      type: "image",
    },
  ];

  const handleBackClick = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const mainIconLabel = searchParams.get("mainIcon");
    navigate(`/LandingPage/?mainIcon=${mainIconLabel}`);
  };

  const handleZoomIn = () => {
    setScale((prev) => Math.min(prev + 0.1, 3)); // Max zoom 3x
  };

  const handleZoomOut = () => {
    setScale((prev) => Math.max(prev - 0.1, 0.5)); // Min zoom 0.5x
  };

  const handleResetZoom = () => {
    setScale(1);
  };

  // Add wheel zoom handler
  const handleWheel = (e) => {
    if (e.ctrlKey) {
      e.preventDefault();
      const delta = e.deltaY * -0.01;
      setScale((prev) => {
        const newScale = prev + delta;
        return Math.min(Math.max(newScale, 0.5), 3);
      });
    }
  };

  React.useEffect(() => {
    // Add wheel event listener for zoom
    const element = document.getElementById("zoom-container");
    if (element) {
      element.addEventListener("wheel", handleWheel, { passive: false });
    }
    return () => {
      if (element) {
        element.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  return (
    <Layout>
      <ChakraProvider>
        <HStack
          height="100vh"
          spacing={0}
          flexDirection={{ base: "column", md: "row" }}
          alignItems="stretch"
        >
          <Flex
            direction={direction}
            spacing={4}
            align="stretch"
            bg="black"
            padding={4}
            width={ribbonWidth}
            height={{ base: "auto", md: "100vh" }}
            overflowY={{ base: "auto", md: "hidden" }}
            flexShrink={0}
          >
            <Button
              onClick={handleBackClick}
              color="white"
              justifyContent="center"
              variant="ghost"
              bg="transparent"
              _hover={{ bg: "gray.500" }}
              _active={{ bg: "gray.400" }}
              py={4}
              width={{ base: "auto", md: "100%" }}
              mx={{ base: 2, md: 0 }}
            >
              <Stack align="center" spacing={1}>
                <Icon as={FaArrowLeft} w={4} h={4} color="white" />
              </Stack>
            </Button>

            <Button
              color="white"
              justifyContent="center"
              variant="ghost"
              bg="transparent"
              _hover={{ bg: "gray.500" }}
              _active={{ bg: "gray.400" }}
              py={7}
              width={{ base: "auto", md: "100%" }}
              mx={{ base: 2, md: 0 }}
            >
              <Stack align="center" spacing={1}>
                <Image src={Report1} boxSize="36px" alt="Report Icon" />
              </Stack>
            </Button>

            {iframeList.map((iframe, index) => (
              <Button
                key={index}
                onClick={() => setCurrentIframe(iframe.src)}
                color="white"
                justifyContent="center"
                variant={currentIframe === iframe.src ? "solid" : "ghost"}
                bg={currentIframe === iframe.src ? "gray.600" : "transparent"}
                _hover={{ bg: "gray.500" }}
                _active={{ bg: "gray.400" }}
                py={7}
                width={{ base: "auto", md: "100%" }}
                mx={{ base: 2, md: 0 }}
              >
                <Stack align="center" spacing={1}>
                  <Icon as={iframe.icon} w={6} h={6} color="white" />
                </Stack>
              </Button>
            ))}
          </Flex>

          <Box
            flex="1"
            width={{ base: "100%", md: "90%" }}
            height={{ base: "calc(100vh - 150px)", md: "100vh" }}
            padding={5}
            overflow="hidden"
            bg="white"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            borderLeft={{ base: "none", md: "none" }}
          >
            <Text fontSize="xl" color="gray.500" mb={2}>
              Process Automation
            </Text>
            <Text fontSize="2xl" color="black" fontWeight="bold" mb={4}>
              Power Automate test
            </Text>

            {/* Zoom Controls */}
            <HStack spacing={2} mb={4} alignSelf="flex-end">
              <Tooltip label="Zoom Out (Ctrl + Mouse Wheel Down)">
                <IconButton
                  icon={<FaSearchMinus />}
                  onClick={handleZoomOut}
                  aria-label="Zoom Out"
                  size="sm"
                  variant="ghost"
                />
              </Tooltip>
              <Tooltip label="Reset Zoom">
                <IconButton
                  icon={<FaUndo />}
                  onClick={handleResetZoom}
                  aria-label="Reset Zoom"
                  size="sm"
                  variant="ghost"
                />
              </Tooltip>
              <Tooltip label="Zoom In (Ctrl + Mouse Wheel Up)">
                <IconButton
                  icon={<FaSearchPlus />}
                  onClick={handleZoomIn}
                  aria-label="Zoom In"
                  size="sm"
                  variant="ghost"
                />
              </Tooltip>
              <Text fontSize="sm" color="gray.500">
                {Math.round(scale * 100)}%
              </Text>
            </HStack>

            {currentIframe && (
              <Box
                id="zoom-container"
                width="100%"
                height="100%"
                border="0px solid gray"
                borderRadius="xl"
                padding="5"
                flex="1"
                display="flex"
                justifyContent="center"
                alignItems="center"
                overflow="auto"
                position="relative"
                css={{
                  "&::-webkit-scrollbar": {
                    width: "8px",
                    height: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#888",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
              >
                <Box
                  transform={`scale(${scale})`}
                  transformOrigin="center"
                  transition="transform 0.2s"
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    src={currentIframe}
                    alt="Power Automate"
                    objectFit="contain"
                    width="100%"
                    height="100%"
                    borderRadius="16px"
                    border="1px solid lightgray"
                  />
                </Box>
              </Box>
            )}
          </Box>
        </HStack>
      </ChakraProvider>
    </Layout>
  );
};

export default PowerAutomate;
