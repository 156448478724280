import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Input,
  Textarea,
  Select,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Code,
  useColorModeValue,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import { FaPlay, FaPlus, FaTrash } from "react-icons/fa";
import { Layout } from "../components/Layout";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import { FaArrowLeft } from "react-icons/fa";

const APIBuilder = () => {
  const [apiName, setApiName] = useState("");
  const [apiDescription, setApiDescription] = useState("");
  const [apiSchema, setApiSchema] = useState([
    {
      name: "",
      type: "",
      description: "",
      fields: [],
    },
  ]);
  const [apiEndpoint, setApiEndpoint] = useState("/api/resources");
  const [apiResponse, setApiResponse] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate hook

  const addSchemaField = (parentIndex = null) => {
    if (parentIndex === null) {
      setApiSchema([
        ...apiSchema,
        {
          name: "",
          type: "",
          description: "",
          fields: [],
        },
      ]);
    } else {
      const newSchema = [...apiSchema];
      newSchema[parentIndex].fields.push({
        name: "",
        type: "",
        description: "",
      });
      setApiSchema(newSchema);
    }
  };

  const updateSchemaField = (parentIndex, index, field, value) => {
    const newSchema = [...apiSchema];
    if (parentIndex === null) {
      newSchema[index][field] = value;
    } else {
      newSchema[parentIndex].fields[index][field] = value;
    }
    setApiSchema(newSchema);
  };

  const deleteSchemaField = (parentIndex, index) => {
    const newSchema = [...apiSchema];
    if (parentIndex === null) {
      newSchema.splice(index, 1);
    } else {
      newSchema[parentIndex].fields.splice(index, 1);
    }
    setApiSchema(newSchema);
  };

  const renderSchemaStructure = () => {
    const renderField = (field, parentIndex, index) => {
      return (
        <div key={`${parentIndex}-${index}`}>
          {field.name}
          {field.fields &&
            field.fields.map((nestedField, nestedIndex) => (
              <div
                key={`${parentIndex}-${index}-${nestedIndex}`}
                style={{ marginLeft: "20px" }}
              >
                ↳{" "}
                {renderField(
                  nestedField,
                  `${parentIndex}-${index}`,
                  nestedIndex
                )}
              </div>
            ))}
        </div>
      );
    };

    return apiSchema.map((field, index) => renderField(field, null, index));
  };
  const testAPI = async () => {
    try {
      const response = await fetch(apiEndpoint);
      const data = await response.json();
      setApiResponse(data);
    } catch (error) {
      setApiResponse({ error: error.message });
    }
  };

  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const schemaBoxBgColor = useColorModeValue("gray.50", "gray.700");
  const schemaBoxTextColor = useColorModeValue("black", "white");

  const handleBackClick = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const mainIconLabel = searchParams.get("mainIcon");
    navigate(`/LandingPage/?mainIcon=${mainIconLabel}`);
  };

  return (
    <Layout>
      <Box bg={bgColor} p={{ base: 4, md: 8 }} borderRadius="md" shadow="md">
        <Heading size={{ base: "lg", md: "xl" }} mb={6}>
          <IconButton
            icon={<FaArrowLeft />}
            variant="ghost"
            onClick={handleBackClick}
            aria-label="Go back"
            size="sm"
            className="back-button"
            mr={4}
          />
          API Builder
        </Heading>

        <Flex direction="column" gap={{ base: 4, md: 6 }}>
          <Box>
            <Heading size="md" mb={2}>
              API Details
            </Heading>
            <Flex direction="column" gap={{ base: 2, md: 4 }}>
              <Input
                placeholder="API Name"
                value={apiName}
                onChange={(e) => setApiName(e.target.value)}
              />
              <Textarea
                placeholder="API Description"
                value={apiDescription}
                onChange={(e) => setApiDescription(e.target.value)}
              />
              <Input
                placeholder="API Endpoint"
                value={apiEndpoint}
                onChange={(e) => setApiEndpoint(e.target.value)}
              />
            </Flex>
          </Box>

          <Box>
            <Heading size="md" mb={2}>
              API Schema
            </Heading>
            <Tabs>
              <TabList>
                <Tab>Fields</Tab>
                <Tab>Example Response</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Flex direction="column" gap={{ base: 2, md: 4 }}>
                    {apiSchema.length === 0 ? (
                      <Text color="gray.500">
                        No fields added yet. Click the "Add Field" button to get
                        started.
                      </Text>
                    ) : (
                      apiSchema.map((field, index) => (
                        <Box key={index}>
                          <Flex
                            direction={{ base: "column", md: "row" }}
                            justify="space-between"
                            align="center"
                            gap={{ base: 2, md: 4 }}
                          >
                            <Text fontWeight="medium">Field {index + 1}</Text>
                            <Flex
                              direction={{ base: "column", md: "row" }}
                              gap={{ base: 2, md: 4 }}
                            >
                              <Input
                                placeholder="Field Name"
                                value={field.name}
                                onChange={(e) =>
                                  updateSchemaField(
                                    null,
                                    index,
                                    "name",
                                    e.target.value
                                  )
                                }
                              />
                              <Select
                                placeholder="Select Type"
                                value={field.type}
                                onChange={(e) =>
                                  updateSchemaField(
                                    null,
                                    index,
                                    "type",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="string">String</option>
                                <option value="number">Number</option>
                                <option value="boolean">Boolean</option>
                                <option value="array">Array</option>
                                <option value="object">Object</option>
                              </Select>
                              <Textarea
                                placeholder="Field Description"
                                value={field.description}
                                onChange={(e) =>
                                  updateSchemaField(
                                    null,
                                    index,
                                    "description",
                                    e.target.value
                                  )
                                }
                                height="40px" // Adjusted height
                              />
                              <IconButton
                                icon={<FaTrash />}
                                size="sm"
                                colorScheme="red"
                                onClick={() => deleteSchemaField(null, index)}
                                aria-label="Delete field"
                              />
                            </Flex>
                          </Flex>
                          <Box ml={{ base: 0, md: 8 }} mt={4}>
                            <Button
                              leftIcon={<FaPlus />}
                              colorScheme="blue"
                              variant="solid"
                              onClick={() => addSchemaField(index)}
                              mb={2}
                            >
                              Add Nested Field
                            </Button>
                            {field.fields.map((nestedField, nestedIndex) => (
                              <Flex
                                key={nestedIndex}
                                direction={{ base: "column", md: "row" }}
                                justify="space-between"
                                align="center"
                                gap={{ base: 2, md: 4 }}
                              >
                                <Text fontWeight="medium">
                                  Nested Field {nestedIndex + 1}
                                </Text>
                                <Flex
                                  direction={{ base: "column", md: "row" }}
                                  gap={{ base: 2, md: 4 }}
                                >
                                  <Input
                                    placeholder="Field Name"
                                    value={nestedField.name}
                                    onChange={(e) =>
                                      updateSchemaField(
                                        index,
                                        nestedIndex,
                                        "name",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Select
                                    placeholder="Select Type"
                                    value={nestedField.type}
                                    onChange={(e) =>
                                      updateSchemaField(
                                        index,
                                        nestedIndex,
                                        "type",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="string">String</option>
                                    <option value="number">Number</option>
                                    <option value="boolean">Boolean</option>
                                    <option value="array">Array</option>
                                    <option value="object">Object</option>
                                  </Select>
                                  <Textarea
                                    placeholder="Field Description"
                                    value={nestedField.description}
                                    onChange={(e) =>
                                      updateSchemaField(
                                        index,
                                        nestedIndex,
                                        "description",
                                        e.target.value
                                      )
                                    }
                                    height="40px" // Adjusted height
                                  />
                                  <IconButton
                                    icon={<FaTrash />}
                                    size="sm"
                                    colorScheme="red"
                                    onClick={() =>
                                      deleteSchemaField(index, nestedIndex)
                                    }
                                    aria-label="Delete nested field"
                                  />
                                </Flex>
                              </Flex>
                            ))}
                          </Box>
                          {index !== apiSchema.length - 1 && <Divider my={4} />}
                        </Box>
                      ))
                    )}
                    <Button
                      leftIcon={<FaPlus />}
                      colorScheme="blue"
                      variant="solid"
                      onClick={() => addSchemaField()}
                    >
                      Add Field
                    </Button>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Box
                    borderWidth={1}
                    borderColor={borderColor}
                    borderRadius="md"
                    p={4}
                  >
                    <Heading size="md" mb={4}>
                      Example API Response
                    </Heading>
                    {apiResponse ? (
                      <Code
                        colorScheme="gray"
                        whiteSpace="pre-wrap"
                        fontSize="sm"
                      >
                        {JSON.stringify(apiResponse, null, 2)}
                      </Code>
                    ) : (
                      <Text color="gray.500">
                        No response yet. Click the "Test API" button to see an
                        example.
                      </Text>
                    )}
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>

          <Box bg={schemaBoxBgColor} p={4} borderRadius="md">
            <Heading size="md" mb={4} color={schemaBoxTextColor}>
              API Schema Structure
            </Heading>
            <Code whiteSpace="pre" color={schemaBoxTextColor}>
              {renderSchemaStructure()}
            </Code>
          </Box>

          <Flex justify="flex-end" gap={{ base: 2, md: 4 }}>
            <Button leftIcon={<FaPlay />} colorScheme="blue" onClick={testAPI}>
              Test API
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Layout>
  );
};

export default APIBuilder;
