import React, { useState } from "react";
import {
  VStack,
  Box,
  Button,
  useToast,
  useColorModeValue,
  Image,
  Text,
  Card,
  CardBody,
  HStack,
  Heading,
  Table,
  Thead,
  Tbody,
  Flex,
  Tr,
  Th,
  useColorMode,
  Td,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  IconButton,
  Container,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  EditIcon,
  DeleteIcon,
  SettingsIcon,
  DownloadIcon,
} from "@chakra-ui/icons";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import stripeLogo from "../Icons/stripe.png";
import Visa from "../Icons/visa.png";
import MasterCard from "../Icons/mastercard.png";
import AmEx from "../Icons/amex.png";
import Discover from "../Icons/discover.png";

const stripePromise = loadStripe("your_publishable_key");

const CustomCard = ({ color = "blue.500" }) => (
  <Box
    w="full"
    maxW={{ base: "100%", md: "400px" }}
    h={{ base: "180px", md: "200px" }}
    bg={color}
    rounded="xl"
    p={{ base: 4, md: 6 }}
    position="relative"
    boxShadow="xl"
    mb={6}
    mx="auto"
  >
    <Box position="absolute" top={6} right={6} color="white" fontWeight="bold">
      BANK
    </Box>
    <Box
      w={{ base: "40px", md: "50px" }}
      h={{ base: "28px", md: "35px" }}
      bg="golden"
      position="absolute"
      top={6}
      left={6}
      rounded="md"
      bgGradient="linear(to-r, yellow.400, yellow.600)"
    />
    <Box
      position="absolute"
      bottom={{ base: "50px", md: "60px" }}
      left={6}
      color="white"
      letterSpacing={{ base: 2, md: 4 }}
      fontSize={{ base: "md", md: "xl" }}
      fontFamily="mono"
    >
      1234 5678 9012 3456
    </Box>
    <Box position="absolute" bottom={6} left={6} color="white" fontSize="sm">
      CARDHOLDER NAME
    </Box>
    <Box position="absolute" bottom={6} right={6} color="white" fontSize="sm">
      12/24
    </Box>
  </Box>
);

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const [isProcessing, setIsProcessing] = useState(false);
  const [saveCard, setSaveCard] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editingCard, setEditingCard] = useState(null);
  const cardBg = useColorModeValue("gray.600", "gray.700");
  const listBg = useColorModeValue("gray.50", "gray.900");
  const buttonHoverBg = useColorModeValue("gray.100", "gray.700");
  const { colorMode } = useColorMode();
  const [savedCards, setSavedCards] = useState([
    {
      id: 1,
      last4: "4242",
      brand: "visa",
      expMonth: 12,
      expYear: 2024,
      logo: Visa,
    },
    {
      id: 2,
      last4: "5555",
      brand: "mastercard",
      expMonth: 3,
      expYear: 2025,
      logo: MasterCard,
    },
  ]);

  const [invoices, setInvoices] = useState([
    { id: 1, amount: 0.99, date: "2020-12-01", status: "paid" },
    { id: 2, amount: 0.99, date: "2020-11-01", status: "paid" },
  ]);

  // Add these functions in your PaymentForm component
  const handleEditCard = (card) => {
    setEditingCard(card);
    setIsEditing(true);

    // Change the form state to reflect editing mode
    elements?.getElement(CardElement)?.clear();
    setSaveCard(true);

    // Scroll to the form
    document.querySelector(".payment-form")?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const handleDeleteCard = async (cardId) => {
    try {
      // Here you would typically make an API call to delete the card from Stripe
      // await deleteCardFromStripe(cardId);

      setSavedCards((cards) => cards.filter((card) => card.id !== cardId));
      toast({
        title: "Card deleted successfully",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: "Error deleting card",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditingCard(null);
    elements?.getElement(CardElement)?.clear();
  };

  // Update the handleSubmit function
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    try {
      if (isEditing) {
        // Handle card update
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        });

        if (error) throw new Error(error.message);

        // Here you would typically make an API call to update the card in Stripe
        // await updateCardInStripe(editingCard.id, paymentMethod.id);

        // Update the card in local state
        setSavedCards((cards) =>
          cards.map((card) =>
            card.id === editingCard.id
              ? {
                  ...card,
                  last4: paymentMethod.card.last4,
                  expMonth: paymentMethod.card.exp_month,
                  expYear: paymentMethod.card.exp_year,
                  brand: paymentMethod.card.brand,
                }
              : card
          )
        );

        toast({
          title: "Card updated successfully",
          status: "success",
          duration: 3000,
        });

        setIsEditing(false);
        setEditingCard(null);
      } else {
        // Handle new card addition
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        });

        if (error) throw new Error(error.message);

        if (saveCard) {
          // Add new card to saved cards
          const newCard = {
            id: Date.now(), // In production, use the ID from Stripe
            last4: paymentMethod.card.last4,
            brand: paymentMethod.card.brand,
            expMonth: paymentMethod.card.exp_month,
            expYear: paymentMethod.card.exp_year,
            logo: getCardLogo(paymentMethod.card.brand), // Helper function to get logo
          };

          setSavedCards((cards) => [...cards, newCard]);
        }

        toast({
          title: "Card added successfully",
          status: "success",
          duration: 3000,
        });
      }

      elements.getElement(CardElement).clear();
      setSaveCard(true);
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
        duration: 3000,
      });
    }

    setIsProcessing(false);
  };

  // Helper function to get card logo
  const getCardLogo = (brand) => {
    switch (brand.toLowerCase()) {
      case "visa":
        return Visa;
      case "mastercard":
        return MasterCard;
      case "amex":
        return AmEx;
      case "discover":
        return Discover;
      default:
        return Visa; // Default fallback
    }
  };
  const cardStyle = {
    style: {
      base: {
        fontSize: { base: "16px", md: "18px" },
        fontFamily: "monospace",
        color: useColorModeValue("black", "white"),
        "::placeholder": {
          color: useColorModeValue("gray.500", "gray.400"),
        },
        padding: { base: "16px", md: "20px 16px" },
      },
    },
  };

  return (
    <Container maxW="1200px" px={{ base: 4, md: 6 }}>
      <VStack spacing={{ base: 4, md: 6 }} align="stretch" w="full">
        {/* Stripe Logo */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          w="full"
          py={{ base: 4, md: 6 }}
        >
          <Image
            src={stripeLogo}
            alt="Stripe"
            h={{ base: "80px", md: "120px" }}
            objectFit="contain"
          />
        </Box>

        {/* Title */}
        <Box px={{ base: 2, md: 4 }}>
          <Heading
            size={{ base: "md", md: "lg" }}
            textAlign="left"
            color={useColorModeValue("gray.700", "white")}
          >
            Payment Methods
          </Heading>
          <Text
            color={useColorModeValue("gray.600", "gray.300")}
            fontSize={{ base: "sm", md: "md" }}
            mt={1}
          >
            Add or manage your payment methods
          </Text>
        </Box>

        {/* Divider */}
        <Box
          w="full"
          h="2px"
          bg={useColorModeValue("gray.200", "gray.700")}
          mb={{ base: 2, md: 4 }}
        />

        {/* Main Content */}
        <Flex
          direction={{ base: "column", lg: "row" }}
          gap={{ base: 4, md: 8 }}
          align="flex-start"
          justify="center"
        >
          {/* Left Column */}
          <Box flex="1" maxW={{ base: "full", lg: "500px" }}>
            {/* Card Brand Logos */}
            <Flex justify="center" w="full" mb={4}>
              <HStack
                spacing={{ base: 2, md: 6 }}
                maxW="400px"
                w="full"
                justify="space-between"
              >
                {[
                  { src: Visa, alt: "Visa" },
                  { src: MasterCard, alt: "Mastercard" },
                  { src: AmEx, alt: "American Express" },
                  { src: Discover, alt: "Discover" },
                ].map((card) => (
                  <Box
                    key={card.alt}
                    p={{ base: 1.5, md: 2 }}
                    bg="white"
                    rounded="md"
                    boxShadow="sm"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={{ base: "48px", md: "60px" }}
                    height={{ base: "32px", md: "40px" }}
                  >
                    <Image
                      src={card.src}
                      alt={card.alt}
                      w="full"
                      h="full"
                      objectFit="contain"
                      p={1}
                    />
                  </Box>
                ))}
              </HStack>
            </Flex>

            <CustomCard color="blue.500" />

            {/* Payment Form Card */}
            <Card
              variant="outline"
              w="full"
              maxW={{ base: "full", md: "400px" }}
              boxShadow="lg"
              borderRadius="xl"
              mx="auto"
              className="payment-form"
              bg={useColorModeValue("white", "gray.800")}
            >
              <CardBody p={{ base: 3, md: 4 }}>
                <form onSubmit={handleSubmit}>
                  <VStack spacing={3} align="stretch">
                    <Heading
                      size="xs"
                      mb={2}
                      color={useColorModeValue("gray.700", "gray.200")}
                    >
                      {isEditing ? "Edit Card" : "Card Details"}
                    </Heading>

                    <VStack spacing={3} w="full">
                      {/* Card Number */}
                      <FormControl>
                        <FormLabel
                          fontSize="xs"
                          mb={1}
                          color={useColorModeValue("gray.600", "gray.400")}
                        >
                          Card Number
                        </FormLabel>
                        <Box
                          w="full"
                          bg="transparent"
                          rounded="md"
                          borderWidth="1px"
                          borderColor={useColorModeValue(
                            "gray.200",
                            "gray.600"
                          )}
                          _hover={{
                            borderColor: useColorModeValue(
                              "gray.300",
                              "gray.500"
                            ),
                          }}
                          py={1.5}
                          px={2}
                        >
                          <CardNumberElement
                            options={{
                              style: {
                                base: {
                                  fontSize: "15px",
                                  fontFamily: "monospace",
                                  letterSpacing: "0.025em",
                                  color: useColorModeValue("gray.800", "white"),
                                  "::placeholder": {
                                    color: useColorModeValue(
                                      "gray.400",
                                      "gray.500"
                                    ),
                                  },
                                  padding: "2px",
                                },
                              },
                            }}
                          />
                        </Box>
                      </FormControl>
                      {/* Card Holder Name */}
                      <FormControl>
                        <FormLabel
                          fontSize="xs"
                          mb={1}
                          color={useColorModeValue("gray.600", "gray.400")}
                        >
                          Card Holder Name
                        </FormLabel>
                        <Input
                          placeholder="Name on card"
                          size="sm"
                          fontSize="sm"
                          borderColor={useColorModeValue(
                            "gray.200",
                            "gray.600"
                          )}
                          _hover={{
                            borderColor: useColorModeValue(
                              "gray.300",
                              "gray.500"
                            ),
                          }}
                          height="32px"
                        />
                      </FormControl>
                      {/* Expiry and CVV */}
                      <SimpleGrid columns={2} spacing={3} w="full">
                        <FormControl>
                          <FormLabel
                            fontSize="xs"
                            mb={1}
                            color={useColorModeValue("gray.600", "gray.400")}
                          >
                            Expiry Date
                          </FormLabel>
                          <Input
                            placeholder="MM/YY"
                            size="sm"
                            fontSize="sm"
                            borderColor={useColorModeValue(
                              "gray.200",
                              "gray.600"
                            )}
                            _hover={{
                              borderColor: useColorModeValue(
                                "gray.300",
                                "gray.500"
                              ),
                            }}
                            height="32px"
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel
                            fontSize="xs"
                            mb={1}
                            color={useColorModeValue("gray.600", "gray.400")}
                          >
                            CVV
                          </FormLabel>
                          <Input
                            placeholder="***"
                            size="sm"
                            type="password"
                            maxLength={3}
                            fontSize="sm"
                            borderColor={useColorModeValue(
                              "gray.200",
                              "gray.600"
                            )}
                            _hover={{
                              borderColor: useColorModeValue(
                                "gray.300",
                                "gray.500"
                              ),
                            }}
                            height="32px"
                          />
                        </FormControl>
                      </SimpleGrid>
                    </VStack>

                    <HStack w="full" spacing={2} mt={2}>
                      <Button
                        colorScheme="blue"
                        type="submit"
                        isLoading={isProcessing}
                        size="sm"
                        flex="1"
                        fontSize="sm"
                      >
                        {isEditing ? "UPDATE" : "PAY NOW"}
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        flex="1"
                        fontSize="sm"
                        onClick={isEditing ? handleCancelEdit : undefined}
                      >
                        {isEditing ? "CANCEL" : "CLEAR"}
                      </Button>
                    </HStack>

                    {!isEditing && (
                      <Box w="full" mt={1}>
                        <Checkbox
                          colorScheme="blue"
                          size="sm"
                          spacing={2}
                          isChecked={saveCard}
                          onChange={(e) => setSaveCard(e.target.checked)}
                        >
                          <Text fontSize="xs">
                            Save card for future payments
                          </Text>
                        </Checkbox>
                      </Box>
                    )}

                    <Text
                      fontSize="xs"
                      color="gray.500"
                      textAlign="center"
                      mt={1}
                    >
                      Your payment information is processed securely by Stripe.
                    </Text>
                  </VStack>
                </form>
              </CardBody>
            </Card>
          </Box>

          {/* Desktop Divider */}
          <Box
            display={{ base: "none", lg: "block" }}
            position="relative"
            width="2px"
            bg={useColorModeValue("gray.200", "gray.700")}
            alignSelf="stretch"
            mx={4}
          >
            <Box
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              bg={useColorModeValue("gray.100", "gray.800")}
              p={2}
              rounded="full"
            >
              <Text fontSize="sm" color="gray.500">
                OR
              </Text>
            </Box>
          </Box>

          {/* Mobile Divider */}
          <Flex
            display={{ base: "flex", lg: "none" }}
            w="full"
            alignItems="center"
            my={4}
          >
            <Box
              flex="1"
              h="2px"
              bg={useColorModeValue("gray.200", "gray.700")}
            />
            <Text mx={4} color="gray.500" fontSize="sm">
              OR
            </Text>
            <Box
              flex="1"
              h="2px"
              bg={useColorModeValue("gray.200", "gray.700")}
            />
          </Flex>

          {/* Saved Cards Section */}
          <Box
            flex="1"
            w="full"
            maxW={{ base: "full", lg: "400px" }}
            bg={listBg}
            p={{ base: 4, md: 6 }}
            rounded="xl"
          >
            <Heading size={{ base: "sm", md: "md" }} mb={4}>
              Saved Cards
            </Heading>
            <VStack spacing={4}>
              {savedCards.map((card) => (
                <Box
                  key={card.id}
                  w="full"
                  position="relative"
                  bg={cardBg}
                  bgGradient="linear(to-r, gray.600, gray.700)"
                  rounded="xl"
                  p={4}
                  h={{ base: "160px", md: "180px" }}
                  color="white"
                  transition="transform 0.2s"
                  _hover={{ transform: "translateY(-2px)" }}
                >
                  {/* Card Options Menu - Top Right */}
                  <Box position="absolute" top={3} right={3} zIndex={2}>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Card options"
                        icon={<SettingsIcon />}
                        variant="ghost"
                        size="sm"
                        color="white"
                        _hover={{ bg: "whiteAlpha.200" }}
                      />
                      <MenuList
                        bg={colorMode === "light" ? "white" : "#1A202C"}
                        borderColor={
                          colorMode === "light" ? "gray.200" : "gray.700"
                        }
                        boxShadow={colorMode === "light" ? "md" : "dark-lg"}
                      >
                        <MenuItem
                          icon={
                            <EditIcon
                              color={
                                colorMode === "light" ? "gray.600" : "white"
                              }
                            />
                          }
                          onClick={() => handleEditCard(card)}
                          color={colorMode === "light" ? "gray.700" : "white"}
                          _hover={{
                            bg: colorMode === "light" ? "gray.100" : "blue.600",
                          }}
                          fontSize="sm"
                        >
                          Edit Card
                        </MenuItem>
                        <MenuItem
                          icon={
                            <DeleteIcon
                              color={
                                colorMode === "light" ? "red.500" : "red.300"
                              }
                            />
                          }
                          onClick={() => handleDeleteCard(card.id)}
                          color={colorMode === "light" ? "red.600" : "red.300"}
                          _hover={{
                            bg: colorMode === "light" ? "red.50" : "red.700",
                          }}
                          fontSize="sm"
                        >
                          Delete Card
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                  {/* Card Brand Logo - Top Left */}
                  <Box
                    position="absolute"
                    top={4}
                    left={4}
                    w={{ base: "50px", md: "60px" }}
                    h={{ base: "30px", md: "35px" }}
                    bg="white"
                    rounded="md"
                    p={1.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      src={card.logo}
                      alt={card.brand}
                      w="full"
                      h="full"
                      objectFit="contain"
                    />
                  </Box>
                  {/* EMV Chip */}
                  <Box
                    position="absolute"
                    top={{ base: "50px", md: "60px" }}
                    left={4}
                    w={{ base: "40px", md: "45px" }}
                    h={{ base: "28px", md: "32px" }}
                    bg="golden"
                    rounded="md"
                    bgGradient="linear(to-r, yellow.400, yellow.600)"
                  />
                  {/* Card Number */}
                  <Box
                    position="absolute"
                    bottom={{ base: "40px", md: "50px" }}
                    left={4}
                    fontFamily="mono"
                    fontSize={{ base: "md", md: "lg" }}
                    letterSpacing="wider"
                  >
                    •••• •••• •••• {card.last4}
                  </Box>
                  {/* Card Holder & Expiry */}
                  <Flex
                    position="absolute"
                    bottom={4}
                    left={4}
                    right={4}
                    justify="space-between"
                    align="center"
                  >
                    <Text fontSize="sm" fontFamily="mono">
                      CARD HOLDER NAME
                    </Text>
                    <Text fontSize="sm" fontFamily="mono">
                      {card.expMonth.toString().padStart(2, "0")}/
                      {card.expYear.toString().slice(-2)}
                    </Text>
                  </Flex>
                  {/* Background Pattern */}
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    bgGradient="radial(circle at 50% -20%, whiteAlpha.100 0%, transparent 35%)"
                    rounded="xl"
                    pointerEvents="none"
                  />
                </Box>
              ))}
            </VStack>
          </Box>
        </Flex>

        {/* Invoice History Section */}
        <Box
          w="full"
          maxW="1200px"
          alignSelf="center"
          bg={useColorModeValue("white", "gray.800")}
          rounded="xl"
          shadow="lg"
          overflow="hidden"
          mt={{ base: 4, md: 6 }}
        >
          <Box
            bg={useColorModeValue("gray.50", "gray.900")}
            px={{ base: 4, md: 6 }}
            py={4}
            borderBottom="1px"
            borderColor={useColorModeValue("gray.200", "gray.700")}
          >
            <Heading size={{ base: "sm", md: "md" }}>Invoice History</Heading>
          </Box>
          <Box overflow="auto">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Amount</Th>
                  <Th>Status</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {invoices.map((invoice) => (
                  <Tr key={invoice.id}>
                    <Td fontSize={{ base: "sm", md: "md" }}>
                      {new Date(invoice.date).toLocaleDateString()}
                    </Td>
                    <Td fontSize={{ base: "sm", md: "md" }}>
                      €{invoice.amount}
                    </Td>
                    <Td>
                      <Badge
                        colorScheme={
                          invoice.status === "paid" ? "green" : "yellow"
                        }
                        px={3}
                        py={1}
                        rounded="full"
                        fontSize={{ base: "xs", md: "sm" }}
                      >
                        {invoice.status}
                      </Badge>
                    </Td>
                    <Td>
                      <Button
                        size={{ base: "xs", md: "sm" }}
                        leftIcon={<DownloadIcon />}
                        variant="ghost"
                        bg="transparent"
                        _hover={{
                          bg: buttonHoverBg,
                        }}
                        onClick={() => {
                          /* Implement download logic */
                        }}
                      >
                        Download
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </VStack>
    </Container>
  );
};

const PaymentTab = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

export default PaymentTab;
